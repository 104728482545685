import { createElement, FC, Fragment } from 'react';
import { PermissionsFor } from './permissions-for';

export const createPermissions = <Permissions extends string>(
  usePermissions: () => PermissionsFor<Permissions>
) => {
  const Permissions: FC<Partial<PermissionsFor<Permissions>>> = ({
    children,
    ...toMatch
  }) => {
    const perms = usePermissions();
    const isMatch = Object.entries(toMatch).every(
      ([perm, value]) => (perms as any)[perm] === value
    );
    return isMatch ? createElement(Fragment, null, children) : null;
  };

  return Permissions;
};

import { Children, createElement, FC, Fragment, isValidElement } from 'react';
import { PermissionsFor } from './permissions-for';

export const createPermissionGroupMatch = <Permissions extends string>(
  usePermissions: () => PermissionsFor<Permissions>
) => {
  const PermissionMatch: FC<Partial<PermissionsFor<Permissions>>> = ({
    children
  }) => createElement(Fragment, null, children);

  const PermissionGroup: FC = ({ children }) => {
    const perms = usePermissions();

    const firstMatch = Children.toArray(children).find((child) => {
      if (isValidElement(child) && child.type === PermissionMatch) {
        const { children: _, ...toMatch } = child.props as any;
        return Object.entries(toMatch).every(
          ([perm, value]) => (perms as any)[perm] === value
        );
      }
      throw new Error(
        'PermissionMatch is the only valid child of PermissionGroup'
      );
    });
    return firstMatch ? createElement(Fragment, null, firstMatch) : null;
  };

  return [PermissionGroup, PermissionMatch] as const;
};

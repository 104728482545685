/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { PermissionResolver } from 'packages/permissions/permission-resolver';
import { Context, createElement, FC, useEffect, useRef, useState } from 'react';
import { Defer, defer } from './defer';
import { PermissionsFor } from './permissions-for';
import { useChanged } from './use-changed';

export const createPermissionProvider = <
  Permissions extends string,
  Alias extends string
>(
  PermissionContext: Context<PermissionsFor<Permissions>>,
  permissionMappings: { [perm in Permissions]: Alias }
): FC<{
  permissionResolver: PermissionResolver<Alias>;
}> => ({ children, permissionResolver }) => {
  type PermissionState = [
    // A promise if we are waiting for values
    Defer<void> | null,
    // An error if any
    Error | null,
    // The permission result if available
    PermissionsFor<Permissions> | null
  ];

  const [state, setState] = useState<PermissionState>([null, null, null]);
  // We will keep our active state here in case we need to override it during render
  const permissionStateRef = useRef<PermissionState>();
  permissionStateRef.current = state;

  const permissionResolverChanged = useChanged(permissionResolver);
  if (permissionResolverChanged && (state[0] || state[1] || state[2])) {
    // Override the state ref to use an initial set of values of null,
    // meaning, we must render null and wait for our effect
    setState((permissionStateRef.current = [null, null, null]));
  }

  // After we first mount, check for our permissions
  useEffect(
    () =>
      // permissionResolver returns an off function
      permissionResolver(
        // Pass in all our values directly
        Object.values(permissionMappings) as Alias[],
        // Pass in a callback function to be notified of updates.
        // This willed be called synchronously and we will set state immediately
        ([loading, err, aliasedPermissions]) => {
          const [currentDeferred] = permissionStateRef.current!;
          if (loading) {
            setState([currentDeferred || defer(), null, null]);
          } else if (err) {
            if (currentDeferred) {
              currentDeferred.reject(err);
            }
            setState([null, err, null]);
          } else {
            if (currentDeferred) {
              currentDeferred.resolve();
            }
            const nextPermState = Object.entries(permissionMappings).reduce(
              (
                perms: PermissionsFor<Permissions>,
                [perm, alias]: /* [Permissions, Alias] */ any
              ) => {
                perms[perm as Permissions] = aliasedPermissions![
                  alias as Alias
                ] as boolean;
                return perms;
              },
              {} as any
            );
            setState([null, null, nextPermState]);
          }
        }
      ),
    [permissionResolver]
  );

  const [deferred, error, permissions] = permissionStateRef.current!;

  const throwable = deferred ? deferred.promise : error;
  if (throwable) throw throwable;

  // On first mount, we will render null and then
  // we will render context / children after
  return permissions
    ? createElement(
        PermissionContext.Provider,
        { value: permissions },
        children
      )
    : null;
};

export type PermissionsNeededByProvider<
  Provider extends FC<{ permissionResolver: PermissionResolver<any> }>
> = Provider extends FC<{ permissionResolver: PermissionResolver<infer Perms> }>
  ? Perms
  : never;

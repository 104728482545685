import { createContext, useContext } from 'react';
import { createPermissionGroupMatch } from './permission-group-match';
import { createPermissionProvider } from './permission-provider';
import { createPermissions } from './permissions';
import { PermissionsFor } from './permissions-for';

export { PermissionsNeededByProvider } from './permission-provider';

export const createReactPermissionBindings = <
  Permissions extends string,
  Alias extends string = string
>(
  permissionMappings: { [perm in Permissions]: Alias }
) => {
  const PermissionContext = createContext<PermissionsFor<Permissions>>(
    null as any
  );
  const PermissionProvider = createPermissionProvider(
    PermissionContext,
    permissionMappings
  );
  const usePermissions = () => useContext(PermissionContext);
  const Permissions = createPermissions(usePermissions);
  const [PermissionGroup, PermissionMatch] = createPermissionGroupMatch(
    usePermissions
  );

  return {
    /**
     * Wrap your app in this provider. This provider needs the permissions resolver instance
     * and will <Suspense /> for your permissions to be ready. If there is an error loading
     * the permissions, the error will be thrown. If your change the instance of the permission
     * resolver,
     */
    PermissionProvider,
    /**
     * Hook to use to get the current set of permissions
     */
    usePermissions,
    /**
     * Component to conditionally render if permissions match
     */
    Permissions,
    /**
     * Parent container to use PermissionMatch
     */
    PermissionGroup,
    /**
     * Match a permission inside a PermissionGroup
     */
    PermissionMatch
  };
};
